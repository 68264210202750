<template>
  <section class="m-address-edit m-scroll">
    <!-- 头部导航 -->
    <m-header :title="title"> </m-header>
    

    <van-form @submit="onSubmit" class="form">
      <van-field
        v-model="surname"
        name="firstname"
        label="姓"
        placeholder="请输入"
        @input="surname=surname.replace(/[\d]/g,'')"
        clearable
        :rules="[{ required: true, message: '不能为空哦' }]"
      />
      <van-field
        v-model="name"
        name="lastname"
        label="名"
        placeholder="请输入"
        @input="surname=surname.replace(/[\d]/g,'')"
        clearable
        :rules="[{ required: true, message: '不能为空哦' }]"
      />
      <van-field
        v-model="phone"
        name="phone"
        label="手机号码"
        type="digit"
        placeholder="请输入"
        clearable
        :rules="[{ required: true, message: '手机错误' }]"
      />
      <van-field
        v-model="email"
        name="email"
        label="邮箱"
        placeholder="请输入"
        clearable
        :rules="[{ required: true, message: '邮箱格式错误', pattern: /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/ }]"
      />
      <van-field
        readonly
        clickable
        name="city"
        :value="cityText"
        label="所在地区"
        placeholder="点击选择地区"
        :rules="[{ required: true, message: '不能为空哦' }]"
        @click="showPicker = true"
      />
      <van-field
        v-if="cityShow"
        v-model="city_name"
        rows="1"
        autosize
        name="city_name"
        label="所在城市"
        placeholder="请输入"
        clearable
        :rules="[{ required: true, message: '不能为空哦' }]"
      />
      <van-field
        v-model="address"
        rows="1"
        autosize
        name="address"
        label="详细地址"
        type="textarea"
        placeholder="如果您住在公寓 请务必填写unit#"
        clearable
        :rules="[{ required: true, message: '不能为空哦' }]"
      />
      <van-field
        v-model="code"
        name="code"
        label="邮编"
        placeholder="请输入"
        clearable
        :formatter="replaceSpeace"
        :rules="[{ required: true, message: '输入正确邮编', pattern: /^[A-Za-z0-9]+$/ }]"
      />
      <div class="footer ">
        <van-button round block type="info" native-type="submit" color="linear-gradient(315deg, #F03C18, #F06448)" class="wave"> 确认 </van-button>
      </div>
    </van-form>



    <!-- 选择地址 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar title="选择地区" :columns="columns" @confirm="onConfirm" @change="change" value-key="ename" :loading='loading' @cancel="showPicker = false" />
    </van-popup>
    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { city, address_add, address_put } from '@/api/zh/mine'

export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      title: '添加地址',
      id: '',
      surname:'',
      name:'',
      phone:'',
      email:'',
      city: [],
      cityShow: false,
      city_name: '',
      cityText: '',
      address:'',
      code:'',
      columns: [],
      showPicker: false,
      loading: false
    }
  },

  created() {
    this.getCity()
  },

  mounted(){
    if(this.$route.query.id){
      this.id       = this.$route.query.id
      this.title    = '修改地址' 
      let addr      = JSON.parse( window.sessionStorage.getItem('address') )
      this.surname  = addr.surname
      this.name     = addr.name
      this.phone    = addr.phone
      this.email    = addr.email
      this.address  = addr.addr
      this.code     = addr.code
      this.cityText = addr.country_arr.ename + '-' + addr.province_arr.ename + (addr.city_arr ?  '-' + addr.city_arr.ename : '')
      this.city_name = addr.city_name
      this.city     = [ addr.country, addr.province, addr.city ]
      if(addr.country == 1) {
        this.cityShow = false
      } else {
        this.cityShow = true
      }
    }else{
      this.id    = ''
      this.title = '添加地址' 
    }
  },

  methods:{
    replaceSpeace(val) {
      return val.replace(' ', '');
    },
    // 获取城市列表
    getCity() {
      this.loading = true
      city().then(res => {
        if(res) {
          this.columns = res.data
          this.columns.forEach(e => {
            e.children.forEach(el => {
              if(e.id == 507) {
                delete el.children
              } else {
                el.children.forEach(ele => {
                  delete ele.children
                })
              }
            })
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    change(picker, values) {
      console.log(picker)
      if(values[0] == 'America') {
        document.getElementsByClassName('van-picker__columns')[0].children[2].style.display = 'none'
      } else {
        document.getElementsByClassName('van-picker__columns')[0].children[2].style.display = 'block'
      }
    },
    // 取人添加
    onSubmit(){
      let params = {
        surname : this.surname,
        name    : this.name,
        phone   : this.phone,
        email   : this.email,
        country : this.city[0],
        province: this.city[1],
        city_name: this.city[0] == 1 ? '' : this.city_name,
        city    : this.city[0] == 1 ? this.city[2] : 0,
        addr    : this.address,
        code    : this.code,
      }
      if(this.id != '') {
        address_put(this.id, params).then(res => {
          if(res) {
            this.$notify({ type: 'success', duration: 2000, message: '修改地址成功' })
            this.$router.go(-1)
          }
        })
      }else {
        address_add(params).then(res => {
          if(res) {
            this.$notify({ type: 'success', duration: 2000, message: '添加地址成功' })
            this.$router.go(-1)
          }
        })
      }
    },

    // 选择地址 popup 确定按钮
    onConfirm(value, ids){
      if(this.columns[ids[0]].id == 507) {
        this.cityShow = true
        value.pop()
      } else {
        this.cityShow = false
      }
      this.cityText = value.join('-')
      this.city = []
      if(ids.length == 3) {
        this.city.push(this.columns[ids[0]].id)
        this.city.push(this.columns[ids[0]].children[ids[1]].id)
        if(this.columns[ids[0]].id == 1) {
          this.city.push(this.columns[ids[0]].children[ids[1]].children[ids[2]].id)
        }
      }
      this.showPicker = false
    }, 
  }
}
</script>

<style lang="less" scoped>
@import './edit.less';
</style>